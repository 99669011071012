import React from "react"
import { Link } from "gatsby";

const Footer = () => (
	<footer class="text-black mt-8">
        <div class="container mx-auto border-top border-solid border-lightgrey py-16">
            <div class="flex flex-col md:flex-row justify-between space-y-6 md:space-y-0">
                <div class="flex flex-col lg:flex-row lg:items-center">
                <h3 class="text-lg font-light mb-0 ml-4 lg:ml-0">ABDELRAZAQ.COM</h3>
                    <div>
                        <ul class="flex flex-col lg:flex-row lg:ml-4 pl-0 mb-0">
                            <li class="list-none">
                                <Link to="/blog" className="text-gray-400 hover:text-black ml-4">
                                    Blog
                                </Link>
                            </li>
                            <li class="list-none">
                                <Link to="/images" className="text-gray-400 hover:text-black ml-4">
                                    Images
                                </Link>
                            </li>
                            <li class="list-none">
                                <Link to="/images/tags" className="text-gray-400 hover:text-black ml-4">
                                    Image Tags
                                </Link>
                            </li>
                            <li class="list-none">
                                <Link to="/mood" className="text-gray-400 hover:text-black ml-4">
                                    Mood
                                </Link>
                            </li>
                            <li class="list-none">
                                <Link to="/cartoons" className="text-gray-400 hover:text-black ml-4">
                                    Cartoons
                                </Link>
                            </li>
                            <li class="list-none">
                                <Link to="/archive" className="text-gray-400 hover:text-black ml-4">
                                    Archive
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;